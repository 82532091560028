import { useEffect, useState } from 'react';
import axios from 'axios';

const Favicon = () => {
  const [faviconUrl, setFaviconUrl] = useState('');

  useEffect(() => {
    const fetchFaviconUrl = async () => {
      console.log('Fetching favicon URL...'); // Log adicional
      try {
        //const response = await axios.get(`${process.env.REACT_APP_API_URL}/backendapp/favicon/`);
        //console.log('Favicon API response:', response.data);
        //const fullUrl = `${process.env.REACT_APP_API_URL}${response.data.favicon_url}`;
        //console.log('Full favicon URL:', fullUrl);
        //setFaviconUrl(fullUrl);
        setFaviconUrl('https://api.daagas.dev/backendapp/static/images/daagasDevFavicon.jpg');
      } catch (error) {
        console.error('Error fetching favicon URL:', error);
      }
    };

    fetchFaviconUrl();
  }, []);

  useEffect(() => {
    if (faviconUrl) {
      console.log('Setting favicon to:', faviconUrl);
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = faviconUrl;
      document.getElementsByTagName('head')[0].appendChild(link);
      console.log('Favicon link element:', link);
    }
  }, [faviconUrl]);

  return null;
};

export default Favicon;