import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProgrammingLanguages.css';

const ProgrammingLanguages = () => {
  const [languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        setLoading(true);
        //const response = await axios.get(`${process.env.REACT_APP_API_URL}/backendapp/programming-languages/`);
        const response = await axios.get(`https://api.daagas.dev/backendapp/programming-languages/`);
        setLanguages(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching programming languages');
        setLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="programming-languages">
      <h2>Programming Languages</h2>
      <div className="language-grid">
        {languages.map((lang) => (
          <div key={lang.name} className="language-item">
            <img src={lang.icon_url} alt={lang.name} className="language-icon" />
            <p>{lang.name}</p>
            <div className="proficiency-bar">
              <div 
                className="proficiency-fill" 
                style={{ width: `${lang.proficiency}%` }}
              ></div>
            </div>
            <span>{lang.proficiency}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgrammingLanguages;