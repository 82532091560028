import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Header.css';

const Header = () => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        //const response = await axios.get(`${process.env.REACT_APP_API_URL}/backendapp/header-image/`);
        const response = await axios.get(`https://api.daagas.dev/backendapp/header-image/`);
        //setImageUrl(`${process.env.REACT_APP_API_URL}${response.data.image_url}`);
        setImageUrl(`https://api.daagas.dev/backendapp/static/images/header.jpg`);
      } catch (error) {
        console.error('Error fetching image URL:', error);
      }
    };

    fetchImageUrl();
  }, []);

  return (
    <nav className="header-nav">
      <div className="image-container">
        {imageUrl && (
          <div className="image-wrapper">
            <img src={imageUrl} alt="Hello from backend" className="centered-image" />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;