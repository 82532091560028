import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './GithubRepos.css';

const GithubRepos = ({ username }) => {
  const [repos, setRepos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRepos = async () => {
      try {
        setLoading(true);
        //const response = await axios.get(`${process.env.REACT_APP_API_URL}/backendapp/github-repos/${username}/`);
        const response = await axios.get(`https://api.daagas.dev/backendapp/github-repos/${username}/`);
        setRepos(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching repositories');
        setLoading(false);
      }
    };

    fetchRepos();
  }, [username]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="github-repos-container">
      <h2>GitHub repositories for {username}</h2>
      <ul className="repo-list">
        {repos.map(repo => (
          <li key={repo.name}>
            <a href={repo.url} target="_blank" rel="noopener noreferrer">{repo.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GithubRepos;