import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Logo.css';

const Logo = () => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        //const response = await axios.get(`${process.env.REACT_APP_API_URL}/backendapp/logo/`);
        //setImageUrl(`${process.env.REACT_APP_API_URL}${response.data.logo_url}`);
        const response = await axios.get('https://api.daagas.dev/backendapp/logo/')
        setImageUrl(`https://api.daagas.dev/backendapp//static/images/daagasDev.png`);
      } catch (error) {
        console.error('Error fetching image URL:', error);
      }
    };

    fetchImageUrl();
  }, []);

  return (
    <div className="logo-container">
      {imageUrl && (
        <div className="logo-wrapper">
          <img src={imageUrl} alt="Hello from backend" className="centered-logo" />
        </div>
      )}
    </div>
  );
};

export default Logo;