import React, { useEffect } from 'react';
import Header from './components/Header';
import Favicon from './components/Favicon';
import Logo from './components/Logo';
import GithubRepos from './components/GithubRepos';
import ProgrammingLanguages from './components/ProgrammingLanguages';
import Presentation from './components/Presentation';
import Social from './components/SocialLinks';
import ContactForm from './components/ContactForm';
import './App.css';

function App() {
  useEffect(() => {
    document.title = "daagas";
  }, []);

  return (
    <div className="App">
      <Favicon />
      <Logo />
      <Header />
      <Presentation />
      <GithubRepos username="daagas" />
      <ProgrammingLanguages />
      <ContactForm />
      <Social />
    </div>
  );
}

export default App;