import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Presentation.css';

const Presentation = () => {
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        //const response = await axios.get(`${process.env.REACT_APP_API_URL}/backendapp/presentation/`);
        const response = await axios.get("https://api.daagas.dev/backendapp/presentation/");
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, []);

  if (!profileData) return <div>Loading...</div>;

  return (
    <div className="presentation">
      <h2>{profileData.currentPosition.title} at {profileData.currentPosition.company}</h2>
      <p><strong>Start Date:</strong> {profileData.currentPosition.startDate}</p>
      <p>{profileData.summary}</p>

      <h3>Professional Experience</h3>
      <ul>
        {profileData.professionalExperience.map((exp, index) => (
          <li key={index}>{exp.title} at {exp.company}</li>
        ))}
      </ul>

      <h3>Technical Expertise</h3>
      <ul>
        {profileData.technicalExpertise.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>

      <h3>Education</h3>
      <ul>
        {profileData.education.map((edu, index) => (
          <li key={index}>
            {edu.degree} {edu.status ? `(${edu.status})` : ''} - {edu.institution}
          </li>
        ))}
      </ul>

      <h3>Language Skills</h3>
      <ul>
        {profileData.languageSkills.map((lang, index) => (
          <li key={index}>{lang.language}: {lang.level}</li>
        ))}
      </ul>

      <h3>Professional Philosophy</h3>
      <p>"{profileData.professionalPhilosophy}"</p>

      <p>Website: <a href={profileData.website} target="_blank" rel="noopener noreferrer">{profileData.website}</a></p>
    </div>
  );
};

export default Presentation;