// ContactForm.js
import React, { useState } from 'react';
import axios from 'axios';
import './ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let tempErrors = {};
        if (!formData.name.trim()) tempErrors.name = "Name is required";
        if (!formData.email.trim()) {
            tempErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "Email is invalid";
        }
        if (!formData.subject.trim()) tempErrors.subject = "Subject is required";
        if (!formData.message.trim()) tempErrors.message = "Message is required";
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        
        // Real-time validation
        let tempErrors = { ...errors };
        switch (name) {
            case 'name':
                tempErrors.name = value.trim() ? '' : 'Name is required';
                break;
            case 'email':
                tempErrors.email = value.trim() ? '' : 'Email is required';
                if (value.trim() && !/\S+@\S+\.\S+/.test(value)) {
                    tempErrors.email = 'Email is invalid';
                }
                break;
            case 'subject':
                tempErrors.subject = value.trim() ? '' : 'Subject is required';
                break;
            case 'message':
                tempErrors.message = value.trim() ? '' : 'Message is required';
                break;
            default:
                break;
        }
        setErrors(tempErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                //const response = await axios.post(`${process.env.REACT_APP_API_URL}/backendapp/send-email/`, formData);
                const response = await axios.post(`https://api.daagas.dev/backendapp/send-email/`, formData);
                alert('Email sent successfully');
                setFormData({ name: '', email: '', subject: '', message: '' });
                setErrors({});
            } catch (error) {
                alert('Error sending email');
                console.error('Error:', error);
            }
        }
    };

    return (
        <div className="form-container">
            <h2>Contact me</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                />
                {errors.name && <span className="error-message">{errors.name}</span>}
                
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                />
                {errors.email && <span className="error-message">{errors.email}</span>}
                
                <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    placeholder="Subject"
                />
                {errors.subject && <span className="error-message">{errors.subject}</span>}
                
                <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                ></textarea>
                {errors.message && <span className="error-message">{errors.message}</span>}
                
                <button type="submit">Submit</button>
            </form>
        </div>
    );
};

export default ContactForm;