// SocialLinks.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SocialLinks.css';

const SocialLinks = () => {
  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        //const response = await axios.get(`${process.env.REACT_APP_API_URL}/backendapp/social-links/`);
        const response = await axios.get(`https://api.daagas.dev/backendapp/social-links/`);
        setSocialLinks(response.data);
      } catch (error) {
        console.error('Error fetching social links:', error);
      }
    };

    fetchSocialLinks();
  }, []);

  return (
    <section className="social-links-section">
      <h2 className="social-links-title">Follow me on my social</h2>
      <footer className="social-links">
        {socialLinks.map((link) => (
          <a 
            key={link.platform} 
            href={link.profile_url} 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <img src={link.icon_url} alt={link.platform} />
          </a>
        ))}
      </footer>
    </section>
  );
};

export default SocialLinks;